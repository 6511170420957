<template>
  <div>
    <Toolbar/> 
    <Hero />
    <Plans />
    <InfoBanner />
    <Description />
    <Compliant />
    <Footer />
  </div>
</template>

<script>
import Toolbar from "@/components/Toolbars/HomePageMenu.vue";
import MobileToolbar from "@/components/Toolbars/MobileMenu.vue";
import Hero from "@/components/Home/EnterpriseHero.vue";
import Footer from "@/components/Home/Footer.vue";
import Compliant from "@/components/Home/Compliant.vue";
import Plans from "@/components/Home/EnterprisePlans.vue";
import Description from "@/components/Home/EnterpriseDescription.vue";
import InfoBanner from "@/components/Home/InfoBanner.vue";

export default {
  name: "Home",
  components: {
    Hero,
    InfoBanner,
    Toolbar,
    MobileToolbar,
    Description,
    Plans,
    Compliant,
    Footer,
  },
};
</script>

