<template>
  <div class="gradient_home d-flex justify-center">
    <img
      class="gradient_image"
      src="/img/static/alesia-kazantceva-283291.jpg"
      alt=""
    />
    <div class="main_container">
      <img class="logo py-10" src="/img/static/logo-landing.svg" alt="" />
      <div class="weight-300 font-40 py-10">
      {{ $t("enterprise.heroTitle") }}
      </div>
      <div class="weight-300 font-14 py-20 line-height-25">
        {{ $t("enterprise.heroSubtitle1") }}
        <br />
          {{ $t("enterprise.heroSubtitle2") }}
      </div>
      <div class="py-20 ml-n25 d-flex wrap align-center">    
        <img class="img" src="img/static/hipaa_1-p-500.png" alt="" />
            <img class="img" src="img/static/eu-gdpr-p-500.png" alt="" />
           <img class="img smallerimg" src="img/static/epam-systems-logo-vector-white.svg" alt="" />
        <img class="img" src="img/static/kbc-logo-white.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
* {
  transition: all ease 0.2s;
}

.img {

   max-height: 80px;
   height: 60px;
  padding: 20px;
  width: 100%;
  max-width: 140px;
}

.smallerimg{
  max-height: 40px;
}

.box {
  box-sizing: border-box;
  width: 230px;
  height: 230px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: #50575e;
  background-color: hsla(0, 0%, 100%, 0.03);
  text-align: center;
  text-decoration: none;
}

.box:hover {
  border-color: rgba(255, 255, 255, 0.25);
  background-color: rgba(255, 255, 255, 0.05);
}

.logo {
  max-width: 200px;
  filter: brightness(0) invert(1);
}

.icon_home {
  max-width: 50px;
}

.ml-n25{

    margin-left: -25px;

}

.main_container {
  padding-bottom: 150px;
  padding-top: 150px;
}

.gradient_home {
  position: relative;
  min-height: 100vh;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  object-fit: cover;
}

.gradient_image {
  position: absolute;
  filter: sepia(80%) saturate(150%) brightness(50%) hue-rotate(140deg);
  left: 0;
  top: 0;
  min-height: 100vh;
  height: 100%;
  min-width: 100%;

  z-index: -1;
}

.link {
  margin-top: 30px;
  height: 40px;
  background-color: #151b22;
  font-size: 14px;
  color: white;
  padding: 10px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;

  outline: none;
}

.link:hover {
  background-color: #425464;
}
</style>
