<template>
  <div class="container center d-col">
    <div class="font-28 text-center main_container">
      {{ $t("home.plans.title") }}
    </div>
    <div class="main_container center gap-20">
      <div class="info_item item" v-for="item in items" :key="item.idx">
        <div class="row w100 space-between">
          <div v-if="item.saving" class="green align-center px-10">
            <span class="white--text font-14">
              {{ item.saving }} {{ $t("enterprisePlans.savings") }}
            </span>
          </div>
          <div class="w100 d-flex justify-end highlight">
            {{ item.title }}
            <div class="highlighter"></div>
          </div>
        </div>
        <div class="info_title">{{ item.seats }}</div>
        <div class="outlineinfo"></div>
        <div class="outlineinfo">
          {{ item.price1 }}
        </div>
        <div class="outlineinfo">
          {{ item.price2 }}
        </div>
        <div class="outlineinfo">
          {{ item.price3 }}
        </div>
      </div>
    </div>
    <div class="main_container center gap-20">
      <div class="info_item">
        <div class="w100 d-flex center highlight font-24">
          {{ $t("enterprisePlans.o1") }}
          <div class="highlighter"></div>
        </div>
        <div class="outlineinfo"></div>
        <div class="outlineinfo">{{ $t("enterprisePlans.o2") }}</div>
        <div class="outlineinfo">{{ $t("enterprisePlans.o3") }}</div>
        <div class="outlineinfo">{{ $t("enterprisePlans.o4") }}</div>
        <div class="outlineinfo">{{ $t("enterprisePlans.o5") }}</div>
        <div class="outlineinfo">{{ $t("enterprisePlans.o6") }}</div>
        <div class="outlineinfo">{{ $t("enterprisePlans.o7") }}</div>
        <div class="outlineinfo">{{ $t("enterprisePlans.o8") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { mapState } from "vuex";
export default {
  computed: {
    currency() {
      return this.location.currency;
    },
    price() {
      return process.env.VUE_APP_PRICE;
    },
    items() {
      return [
        {
          title: this.$t("enterprisePlans.b1"),
          saving: "55%",
          seats: this.$t("enterprisePlans.b2"),
          price1: this.$t("enterprisePlans.b3"),
          price2: this.$t("enterprisePlans.b4"),
          price3: this.$t("enterprisePlans.b5"),
        },
        {
          title: this.$t("enterprisePlans.s1"),
          saving: "60%",
          seats: this.$t("enterprisePlans.s2"),
          price1: this.$t("enterprisePlans.s3"),
          price2: this.$t("enterprisePlans.s4"),
          price3: this.$t("enterprisePlans.s5"),
        },
        {
          title: this.$t("enterprisePlans.g1"),
          saving: "65%",
          seats: this.$t("enterprisePlans.g2"),
          price1: this.$t("enterprisePlans.g3"),
          price2: this.$t("enterprisePlans.g4"),
          price3: this.$t("enterprisePlans.g5"),
        },
        {
          title: this.$t("enterprisePlans.d1"),
          saving: "70%",
          seats: this.$t("enterprisePlans.d2"),
          price1: this.$t("enterprisePlans.d3"),
          price2: this.$t("enterprisePlans.d4"),
          price3: this.$t("enterprisePlans.d5"),
        },
        {
          title: this.$t("enterprisePlans.p1"),
          seats: this.$t("enterprisePlans.p2"),
          price1: this.$t("enterprisePlans.p3"),
          price2: this.$t("enterprisePlans.p3"),
          price3: this.$t("enterprisePlans.p3"),
        },
      ];
    },
    ...mapState("user", ["location"]),
  },
  mounted() {
    gsap.from(".item-00", {
      scrollTrigger: ".item-00",
      x: 0,
      y: 50,
      opacity: 0,
      duration: 0.75,
      delay: 0.2,
      ease: "power1.out",
    });
    gsap.from(".item-11", {
      scrollTrigger: ".item-11",
      x: 0,
      y: 50,
      opacity: 0,
      duration: 0.75,
      delay: 0.3,
      ease: "power1.out",
    });
    gsap.from(".item-22", {
      scrollTrigger: ".item-22",
      x: 0,
      y: 50,
      opacity: 0,
      duration: 0.75,
      delay: 0.4,
      ease: "power1.out",
    });
  },
};
</script>

<style scoped lang="less">
* {
  transition: all ease 0.2s;
}

.item {
  max-width: 190px;
  min-width: 200px;
  max-height: 192px;
}

.outlineinfo {
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.container {
  background-color: var(--white);
  color: var(--almost-black);
  padding-top: 50px;
}

.highlight {
  line-height: 40px;
}

.highlighter {
  margin-left: 20px;
  border-right: 2px solid var(--green);
  line-height: 40px;
}

.main_container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 72px;
  padding-top: 40px;
  grid-column-gap: 20px;
}

.info_logo {
  max-width: 50px;
}

.info_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
  border: 1px solid #ddd;
  padding-top: 20px;
  padding-bottom: 50px;
}

.info_title {
  font-size: 24px;
  line-height: normal;
  margin-top: 36px;
  margin-bottom: 10px;
  color: var(--almost-black);
}

.info_body {
  font-size: 13px;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 20px;
  color: #616161;
}

.link {
  margin-top: 40px;

  background-color: #409875;
  font-size: 14px;
  color: white;
  padding: 10px;

  padding-left: 20px;
  padding-right: 20px;

  outline: none;
}

.link:hover {
  background-color: #56bc93;
}

@media only screen and (max-width: 740px) {
  .main_container {
    flex-direction: column;
  }

  .info_item2 {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
