<template>
  <div class="container center">
    <div class="main_container grow center box">
      <div class="grow description">
        <div class="font-28">
         {{ $t("enterprise.title") }}
        </div>
        <p class="opacity-60">
           {{ $t("enterprise.subtitle") }}
        </p>
      </div>
      <div class="grow">
        <img class="image" src="img/static/stock-photo-enterprise.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
  mounted() {
    gsap.from(".description", {
      scrollTrigger: ".description",
      x: 50,
      y: 0,
      opacity: 0,
      duration: 0.75,
      delay: 0.2,
      ease: "power1.out",
    });
    gsap.from(".image", {
      scrollTrigger: ".image",
      x: 0,
      y: 50,
      opacity: 0,
      duration: 0.75,
      delay: 0.3,
      ease: "power1.out",
    });
  },
};
</script>

<style scoped>
.container {
  background-color: white;
  color: var(--almost-black);
  padding-top: 100px;
  padding-bottom: 120px;
  overflow: hidden;
}

.box {
  grid-column-gap: 80px;
  grid-row-gap: 40px;
}

.image {
  max-width: 600px;
  width: 100%;
  z-index: 3;
}

.link {
  margin-top: 30px;
  height: 40px;
  background-color: #409875;
  font-size: 14px;
  color: white;
  padding: 10px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;

  outline: none;
}

.link:hover {
  background-color: #56bc93;
}

@media only screen and (max-width: 795px) {
  .box {
    flex-direction: column;
    flex-direction: column-reverse;
  }
}
</style>